// scope to root component
dgo-navbar-utility-nav {
    // pvd3 style imports
    @import '@fmr-ap109253/providence/dist/scss/pvd-link/pvd-link';
    @import '@fmr-ap109253/providence/dist/scss/pvd-button/pvd-button';

    .psw-navbar-pvd-button-root.pvd-button--tertiary .pvd-button__icon {
        color: #368727;
    }

    .psw-navbar-pvd-button-root.pvd-button--tertiary .pvd-button__text {
        color: #368727;
    }

    .psw-navbar-pvd-icon-root {
        display: inline-block;
        fill: currentColor;
        height: 1.25rem;
        position: relative;
        stroke: none;
        width: 1.25rem;
    }
    
    .psw-navbar-pvd-icon-root.pvd-icon--small {
        height: 1rem;
        width: 1rem;
    }
}
