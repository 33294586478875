// enable helios
$pvd-enable-helios-styles: false;
// namespace pvd
$pvd-class-prefix: 'psw-navbar-pvd';

// pvd3 mixins - emits no style
@import '@fmr-pr101742/providence-visual-style/styles/visual-style';
@import 'app/components/DGO/styles/override.scss';

// scope to root component
psw-navbar-component {
    // Resets typographic styles to insulate against inherited page styles
    @include pvd-typography-reset();
    @include pvd-box-sizing();

    // pvd3 style imports
    @import '@fmr-ap109253/providence/dist/scss/pvd-icon/pvd-icon';
    @import '@fmr-ap109253/providence/dist/scss/pvd-spinner/pvd-spinner';
    @import '@fmr-ap109253/providence/dist/scss/pvd-link/pvd-link';
    @import '@fmr-ap109253/providence/dist/scss/pvd-expand-collapse/pvd-expand-collapse';
    @import '@fmr-ap109253/providence/dist/scss/pvd-inline-alert/pvd-inline-alert';
    @import '@fmr-ap109253/providence/dist/scss/pvd-field-group/pvd-field-group';
    @import '@fmr-ap109253/providence/dist/scss/pvd-select/pvd-select';
    @import '@fmr-ap109253/providence/dist/scss/pvd-microcopy/pvd-microcopy';
    @import '@fmr-ap109253/providence/dist/scss/pvd-title/pvd-title';

    .screen-reader-only {
        @include pvd-screen-reader-only();
    }

    // Resets "visited" state color on the client chooser link on navigation bar
    .psw-navbar-pvd-link-root .pvd-link__link:visited {
        color: #356f95;
    }

    .advisorSpinner {
        align-items: center;
        background-color: #ffffff;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 9999;
    }
}
